import React from "react";
import styled from "styled-components";

const ContactContainer = styled.div`
  padding: 20px;
`;

const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 10px;
`;

const ContactInfo = styled.p`
  font-size: 0.9rem;
`;

const Contact = () => (
  <ContactContainer>
    <div className="bubble-card">
      <Heading>Contact Us</Heading>
      <ContactInfo>
        📱:{" "}
        <a href="tel:863-236-9198" className="email-link">
          (863) 236-9198
        </a>
      </ContactInfo>
      {/* <ContactInfo>
        ✉️:{" "}
        <a
          href="mailto:cs@softspray.solutions?subject=Info Request&body=Greetings, I would like a quote for..."
          className="email-link"
        >
          cs@softspray.solutions
        </a>
      </ContactInfo> */}
      <ContactInfo>
        📍 Polk County Florida
        <p>Auburndale</p>
        <p>Bartow</p>
        <p>Davenport</p>
        <p>Dundee</p>
        <p>Eagle Lake</p>
        <p>Fort Meade</p>
        <p>Frostproof</p>
        <p>Haines City</p>
        <p>Highland Park</p>
        <p>Hillcrest Heights</p>
        <p>Lake Alfred</p>
        <p>Lake Hamilton</p>
        <p>Lakeland</p>
        <p>Lake Wales</p>
        <p>Mulberry</p>
        <p>Polk City</p>
        <p>Winter Haven</p>
      </ContactInfo>
    </div>
  </ContactContainer>
);

export default Contact;
